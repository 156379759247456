:root {
  /* Colors: */
--color-gray-5: #1A1A1A;
--dark-color-bg: #1F1F1F;
--dark-color-brand: #F51F49;
--dark-color-warning: #DB4D46;
--color-attention: #C56C00;
--color-warning: #8F001C;
--dark-color-attention: #EC8200;
--dark-negitive-loss: #D84530;
--dark-color-link: #5279B9;
--dark-color-primary-hover: #4997AC;
--dark-color-primary: #22839D;
--dark-gray-2: #474747;
--dark-gray-1: #333333;
--dark-gray-5: #EBEBEB;
--dark-gray-4: #C2C4C3;
--dark-gray-n: #858585;
--color-negitive-loss: #B20023;
--neutral: #4997AB;
--color-positive: #1B8D2F;
--color-10-success: #E5F5E5;
--color-gray-4: #5C5C5C;
--color-gray-n: #999999;
--color-gray-2: #CCCCCC;
--color-gray-1: #EEEEEE;
--color-gray-bg: #F8F8F8;
--color-success: #009900;
--color-info: #782080;
--color-primary-hover: #00495B;
--color-primary: #006D89;
/* --color-brand: #D6002A; */
--color-brand: #babfc7;
--dark-theme-page-background: #11151D;
--color-link: #0271BB;
--dark-color-success: #78A836;
--dark-color-info: #771263;
--color-black: #000000;
--color-white: #FFFFFF;
--unnamed-color-32363b: #32363B;
--unnamed-color-eaeef4: #EAEEF4;

/* Font/text values */
--unnamed-font-family-akkurat-ll: Akkurat LL;
--unnamed-font-style-normal: normal;
--unnamed-font-weight-normal: normal;
--unnamed-font-weight-bold: bold;
--unnamed-font-size-11: 11px;
--unnamed-font-size-12: 12px;
--unnamed-font-size-14: 14px;
--unnamed-font-size-16: 16px;
--unnamed-font-size-18: 18px;
--unnamed-font-size-20: 20px;
--unnamed-font-size-24: 24px;
--unnamed-font-size-36: 36px;
--unnamed-character-spacing-0: 0px;
--unnamed-character-spacing-0-11: 0.11px;
--unnamed-character-spacing-0-12: 0.12px;
--unnamed-character-spacing-0-14: 0.14px;
--unnamed-character-spacing--0-36: -0.36px;
--unnamed-line-spacing-14: 14px;
--unnamed-line-spacing-16: 16px;
--unnamed-line-spacing-18: 18px;
--unnamed-line-spacing-21: 21px;
--unnamed-line-spacing-23: 23px;
--unnamed-line-spacing-26: 26px;
--unnamed-line-spacing-31: 31px;
--unnamed-line-spacing-37: 37px;
}

/* Character Styles */
.dark-text-3-bold {
font-family: var(--unnamed-font-family-akkurat-ll);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-18);
line-height: var(--unnamed-line-spacing-23);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-eaeef4);
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:hover{
  cursor: pointer;
  color: #007BFF;
}


/* App.css */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  height: 100%; /* Set the height to 100% */

  /*Added overflow & padding to fix conflic issue of  ContextMenuInput */
  padding-right: 0 !important;
  overflow: auto !important;
}

.app-container {
  display: flex;
  height: 100%; /* Set the height to 100% */
}

.main-content {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}

.main-panel {
  border: 10px solid ;
  display: block;
}

.header {
  background-color: var(--color-white);
  height: 55px;
  width: 99vw;
  position: fixed;
  top: 0;
  z-index: 2;
}

.header-display {
  background-color: var(--color-white);
  color: var(--unnamed-color-32363b);
  border-top: 1px solid var(--color-gray-2);
  /* border-top: 2px solid var(--color-brand); */
  height: 100%;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-2);
}

.content-display {
  background-color: var(--color-white);
  color: var(--unnamed-color-32363b);
  height: 30px;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-2);
}

.header-hr {
  color: var(--color-gray-2);
  margin-top: 0;
  margin-bottom: 0;
  border-style: "solid"
}

.notif-btn {
  background-image: url(/src/assets/header/bell.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.action-btn {
  background-image: url(/src/assets/header/circle-question.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.profile-btn {
  background-image: url(/src/assets/header/profile.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.profile-btn-active, .action-btn-active, .notif-btn-active {
  border-bottom: 3px solid var(--color-brand) !important;
}

.profile-text {
  /* color: var(--color-gray-4); */
}

.profile-menu {
  width: 345px;
  height: 1014px;
  background: var(--color-gray-bg) 0% 0% no-repeat padding-box;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  box-shadow: -5px 5px 8px #C1C5CA80;
  opacity: 1;
  margin: auto;
}

.inner-icon {
  width: 64px;
  height: 64px;
  background: var(--color-gray-4) 0% 0% no-repeat padding-box;
  background: #5C5C5C 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: auto;
  border-radius: 50%;
  margin-bottom: 35px;
}

.initials-text {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-36)/var(--unnamed-line-spacing-37) var(--unnamed-font-family-akkurat-ll);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-eaeef4);
  text-align: left;
  font: normal normal normal 36px/37px Akkurat LL;
  letter-spacing: 0px;
  color: #EAEEF4;
  opacity: 1;
  text-align: center;
  padding-top: 13px;
}

.profile-details {
  margin-left: 18px;
}

.profile-name {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-26) var(--unnamed-font-family-akkurat-ll);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-32363b);
  text-align: left;
  font: normal normal bold 20px/26px Akkurat LL;
  letter-spacing: 0px;
  color: #32363B;
  opacity: 1;
  margin: 0;
  padding: 0;
}

.profile-email {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-akkurat-ll);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-32363b);
  text-align: left;
  font: normal normal normal 16px/21px Akkurat LL;
  letter-spacing: 0px;
  color: #32363B;
  opacity: 1;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.more-details {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-18) Source Sans Pro;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--color-primary);
  text-align: left;
  font: normal normal normal 14px/18px Source Sans Pro;
  letter-spacing: 0px;
  color: #006D89;
  opacity: 1;
}

.extra-details-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.extra-details-key {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/24px var(--unnamed-font-family-akkurat-ll);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-32363b);
  text-align: left;
  font: normal normal bold 16px/24px Akkurat LL;
  letter-spacing: 0px;
  color: #32363B;
  margin:0;
  padding:0;
}

.extra-details-info {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/24px var(--unnamed-font-family-akkurat-ll);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-32363b);
  text-align: left;
  font: normal normal normal 16px/24px Akkurat LL;
  letter-spacing: 0px;
  color: #32363B;
}

.action-details {
  margin-left: 18px;
  margin-right: 18px;
}

.action-menu {
  width: 345px;
  height: 1018px;
  background: var(--color-gray-bg) 0% 0% no-repeat padding-box;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  box-shadow: -5px 5px 8px #C1C5CA80;
  opacity: 1;
  margin: auto;
}

.menu-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-23) var(--unnamed-font-family-akkurat-ll);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-32363b);
  text-align: left;
  font: normal normal bold 18px/23px Akkurat LL;
  letter-spacing: 0px;
  color: #32363B;
  opacity: 1;
}

.action-contact {
  margin-top: 50px;
}

.action-contact-key {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-18) var(--unnamed-font-family-akkurat-ll);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-32363b);
  text-align: left;
  font: normal normal bold 14px/18px Akkurat LL;
  letter-spacing: 0px;
  color: #32363B;
}

.menu-info-text {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-18) var(--unnamed-font-family-akkurat-ll);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-32363b);
  text-align: left;
  font: normal normal normal 14px/18px Akkurat LL;
  letter-spacing: 0px;
  color: #32363B;
}

.notif-details {
  margin-bottom: 30px;
}

.system-notif-details {
  margin-left: 18px;
}

.notif-menu {
  background: var(--color-gray-bg) 0% 0% no-repeat padding-box;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  box-shadow: -5px 5px 8px #C1C5CA80;
  opacity: 1;
  width: 345px;
  height: 1018px;
}

.notification {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-18) var(--unnamed-font-family-akkurat-ll);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-32363b);
  text-align: left;
  font: normal normal normal 14px/18px Akkurat LL;
  letter-spacing: 0px;
  color: #32363B;
  opacity: 1;
  height: 59px;
  padding-left: 10px;
  padding-right: 10px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.notif-success {
  border-left: 5px solid var(--color-success);
}

.notif-warning {
  border-left: 5px solid var(--color-attention);
}

.notif-icon-check {
  width: 16px;
  height: 11px;
  opacity: 1;
  margin-right: 10px;
}

.notif-icon-warning {
  width: 18px;
  height: 17px;
  opacity: 1;
  margin-right: 10px;
}

.file-submit-btn {
  
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--color-white);
  text-align: left;
  font: normal normal normal 16px/21px Akkurat LL;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  background: var(--color-primary) 0% 0% no-repeat padding-box;
}

.title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 22px/26.4px var(--unnamed-font-family-akkurat-ll);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-32363b);
  text-align: left;
  font: normal normal normal 22px/26px Akkurat LL;
  letter-spacing: 0px;
  color: var(--unnamed-color-32363b);
  opacity: 1;
}

.content {
  width: 99vw;
  margin-top: 60px;
}

.left-panel {
  top: 10;
  left: 0;
  height: 93vh;
  background-color: #f0f0f0;
  padding: 5px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  overflow-y: auto;
  position: fixed;
}

.login-page {
  /* top: 10;
  left: 0; */
  height: 100vh;
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px;
  box-sizing: border-box;
  /* border-right: 1px solid #ccc; */
  overflow-y: auto;
  /* overflow-x: auto; */
  /* position: fixed; */
  /* z-index: 10; */
}

.left-panel-selected-light {
  /* border-style: outset; */
  background-color: var(--color-gray-n);
  margin: 0;
  padding: 0;
}

.left-panel-selected-dark {
  /* border-style: outset; */
  background-color: var(--color-gray-4);
  margin: 0;
  padding: 0;
}

/* MiddlePanel.css */
.middle-panel {
  margin-left: 0%;
  padding: 0px;
  box-sizing: border-box;
}

/* RightPanel.css */
.right-panel {
  position: sticky;
  /*position: fixed;*/
  overflow-y: scroll;
  /* margin-left: 10%; */
  top: 0;
  right: 0;
  width: 100%;
  height: 93vh;
  box-sizing: border-box;
  border-left: 1px solid #ccc;
  z-index: 1;
  transform: translateX(0);

  text-decoration: none;
  color: #333;
  font-size: 12px;
  display: block;
  padding: 1px;
  transition: transform 0.5s ease, color 0.3s;
}

/* Add this to your CSS file or style section */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  max-height: 80vh;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

ul li a {
  text-decoration: none;
  color: #333;
  font-size: 12px;
  display: block;
  padding: 1px;
  transition: color 0.3s;
}

tr th {
  text-decoration: none;
  /* color: #1f497d; */
  color: var(--color-gray-3);
  font-size: 12px;
  padding: 1px;
  transition: color 0.3s;
}

td {
  text-decoration: none;
  color: #000000;
  font-size: 12px;
  padding: 1px;
  transition: color 0.3s;
}

ul li a:hover {
  color: #007BFF;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-color:  var(--color-gray-1);
}

.audit-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent overlay */
  z-index: 1100;
}

.audit-popup-content {
  background-color: white;
  border: 1px solid #ccc;
  max-height: 80vh; /* Maximum height, adjust as needed */
  overflow-y: auto; /* Enable vertical scrollbar if content exceeds height */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: "auto"; /* Adjust the width as needed */
  border-radius: 8px;
}

.audit-popup-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.audit-popup-content th, .audit-popup-content td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.audit-popup-content th {
  background-color: #f2f2f2;
}

.audit-popup-content tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.audit-popup-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.audit-popup-content h3 {
  margin-bottom: 10px;
}

.audit-popup-content td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.audit-popup-content td.json-cell {
  white-space: pre-wrap; /* Preserve line breaks in JSON content */
  max-width: 100px;
  overflow: auto;
}

.audit-popup-content .json-key {
  color: #0066cc; /* Color for JSON keys */
}

.audit-popup-content .json-value {
  color: #009900; /* Color for JSON values */
}

.transition-cell {
  transition: background-color 1s ease;
  max-width: 30px; /* Set your desired maximum width */
  text-overflow: ellipsis;
  white-space: normal;
  overflow-y: auto;
}

.transition-cell:hover {
  background-color: lightyellow;
}

.json-content {
  max-height: 100px; /* Set your desired maximum height */
  overflow-y: auto;
}


.audit-popup-content td.transition-cell:first-child {
  max-width: 10px; /* Remove the maximum width for the first column */
}

.item {
  transition: opacity 0.5s ease-out;
}

.fade-out {
  opacity: 0;
  height: 0;
  padding: 0;
  margin: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3000%;
  background: rgba(0, 100, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay-red{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3000%;
  background: rgba(100, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.attribute-popup.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.attribute-div.disabled {
  opacity: 0.5;
}


select:disabled {
  background: none;
  appearance: none; /* For modern browsers */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  opacity: 0.5;
}

.invisible-text{
  color: transparent;
  font-size: 10px;
}


/* start -styles for tableFixHead to keep table header fixed whicl scrolling the body*/
.tableFixHead tbody { 
  display: block; 
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
}

.tableFixHead {
  width:400px;
  table-layout: fixed;
  border-collapse: collapse;
}

.tableFixHead thead tr {
  display: block;
}

.tableFixHead td:not(:first-child){
  padding: 1px 1px;
  min-width: 95px;
}

.tableFixHead td:first-child {
  padding: 1px 1px;
  min-width: 50px;
}

.tableFixHead thead th:not(:first-child){
  min-width: 95px;
}

.tableFixHead thead th:first-child {
  padding: 1px 1px;
  min-width: 50px;
}
/* end - styles for tableFixHead to keep table header fixed whicl scrolling the body*/